import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { switchProp } from 'styled-tools';
import { StyledResetButton } from '~/shared/components/Button';

export const StyledAccordion = styled.div();

type Variants = {
    variant: 'default' | 'condensed' | 'submenu' | 'filter';
};

type ButtonProps = Variants & { expanded: boolean };

export const StyledAccordionItem = styled.div<Variants>(
    ({ theme }) => ({
        width: '100%',
        borderTop: theme.traits.line.asBorderStyle,
        borderBottom: theme.traits.line.asBorderStyle,
        display: 'block',
        listStyle: 'none',

        '& + &': {
            borderTop: 'none',
        },
    }),
    switchProp('variant', {
        filter: ({ theme }) => ({
            borderTop: `1px solid ${theme.colors.light50}`,
            borderBottom: `1px solid ${theme.colors.light50}`,
        }),
        condensed: () => ({
            borderTop: 'none',
            borderBottom: 'none',
        }),
        submenu: () => ({
            borderTop: 'none',
            borderBottom: 'none',
        }),
    })
);

export const StyledAccordionHeader = styled.div({
    display: 'block',
    width: '100%',
    fontSize: '18px',
});

const ResetMotionButton = StyledResetButton.withComponent(m.button);
export const StyledAccordionHeaderButton = styled(ResetMotionButton, {
    shouldForwardProp: (prop: string) => prop !== 'expanded',
})<ButtonProps>(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        textAlign: 'left',
        padding: `${theme.spaces[3]} ${theme.spaces[4]}`,
        // Only applied if fontSizeFluid is false
        fontSize: theme.fontSizes.lg,

        '&:hover': {
            backgroundColor: theme.colors.light30,
        },

        '&:focus-visible': {
            ...theme.focus,
            outlineOffset: '-1px',
        },
    }),
    switchProp('variant', {
        condensed: ({ theme }) => ({
            padding: `${theme.spaces[3]} 0`,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        }),
        submenu: ({ theme }) => ({
            padding: 0,
            color: theme.colors.dark,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        }),
    })
);

export const StyledAccordionIconWrapper = styled.div();

export const StyledAccordionContent = styled(m.div)({
    display: 'block',
    overflow: 'hidden',
});

export const StyledAccordionContentInner = styled.div<Variants>(
    ({ theme }) => ({
        padding: `${theme.spaces[3]} ${theme.spaces[4]}`,
    }),
    switchProp('variant', {
        condensed: ({ theme }) => ({
            padding: `${theme.spaces[3]} 0`,
        }),
        submenu: ({ theme }) => ({
            padding: `0 0 ${theme.spaces[2]} ${theme.spaces[3]}`,
        }),
    })
);

export const StyledDefaultIcon = styled.div(({ theme }) => ({
    fontSize: theme.fontSizes['2xl'],
    path: {
        strokeWidth: '2px',
    },
}));
