import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { LineProps } from './Line';

type StyledLineProps = LineProps;

export const StyledLine = styled.div<StyledLineProps>(
    ({ theme, thickness, shade = 'color' }) => ({
        width: '100%',
        height: thickness || theme.sizes.borderWidth,
        backgroundColor: theme.traits.line[shade],
    }),
    ifProp('color', ({ color }) => ({
        backgroundColor: color,
    })),
    switchProp('alignment', {
        vertical: ({ thickness, theme }) => ({
            height: '100%',
            width: thickness || theme.sizes.borderWidth,
        }),
    })
);
