import React from 'react';
import LeftArrow from '$icons/arrow-left.svg';
import { Button } from '~/shared/components';
import { useTranslation } from '~/shared/utils';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden';

type Props = { onBack?: () => void };

export const BackArrow = ({ onBack }: Props) => {
    const { translate } = useTranslation();

    return (
        <Button shape="icon" size="sm" variant="secondary" onClick={onBack}>
            <LeftArrow />
            <VisuallyHidden>{translate('accessibility.goBack')}</VisuallyHidden>
        </Button>
    );
};
