import styled from '@emotion/styled';
import { memo, PropsWithChildren } from 'react';
import { breakpoints } from '~/theme';

type BreakpointKey = keyof typeof breakpoints;
type BreakpointStyles = Record<BreakpointKey, Record<BreakpointKey | string, string>>;
type Props = Partial<Record<BreakpointKey, boolean>>;

const StyledVisibility = styled.div<Props & { display: 'none' | 'block' }>((props) => {
    const styleObj = Object.keys(props).reduce((prev, curr) => {
        const key = breakpoints[curr as BreakpointKey] as BreakpointKey;
        if (key) {
            prev[key] = {
                display: props.display,
            };
        }
        return prev;
    }, {} as BreakpointStyles);

    if (props.display === 'block') {
        return {
            display: 'none',
            ...styleObj,
        };
    }

    return styleObj;
});

/**
 * Inline media query component.
 * Can be used to hide/show content based on the current screen size.
 *
 * Example usage:
 * <Visibility.HiddenFrom md>
 *   <div>This content will be hidden on medium screens and up</div>
 * </Visibility.HiddenFrom>
 *
 * <Visibility.VisibleFrom md>
 *   <div>This content will only be visible on medium screens and up</div>
 * </Visibility.VisibleFrom>
 */
export const Visibility = {
    HiddenFrom: memo((props: PropsWithChildren<Props>) => (
        <StyledVisibility display="none" {...props} />
    )),
    VisibleFrom: memo((props: PropsWithChildren<Props>) => (
        <StyledVisibility display="block" {...props} />
    )),
};
