import { useRouter } from 'next/router';
import { StepId } from '~/features/checkout/models/checkoutModel';
import { useFrame } from '~/shared/utils';
import { useCheckoutStepsState } from './useCheckoutStepsState';

export const useCheckoutSteps = () => {
    const checkoutStepsState = useCheckoutStepsState();
    const { data: frame } = useFrame();
    const { replace } = useRouter();

    const checkoutpage = frame?.staticLinks?.checkoutPage?.url;

    const goToStep = (step: StepId) => {
        checkoutStepsState.setActiveStep(step);
        const href = `${checkoutpage}#${step}`;
        replace(href, href, { shallow: true });
        window.scrollTo(0, 0);
    };

    const goToNextStep = () => {
        const nextStep = checkoutStepsState.nextStep();
        goToStep(nextStep);
    };

    const goToPreviousStep = () => {
        const previousStep = checkoutStepsState.previousStep();
        goToStep(previousStep);
    };

    return {
        ...checkoutStepsState,
        goToStep,
        goToNextStep,
        goToPreviousStep,
    };
};
