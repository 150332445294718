import create from 'zustand';
import { StepId } from '../models/checkoutModel';

type CheckoutStepState = {
    steps: StepId[];
    activeStep: StepId;
    state: 'pending' | 'completed';
    setState: (state: CheckoutStepState['state']) => void;
    setActiveStep: (step: StepId) => void;
    getStepState: (step: StepId) => 'active' | 'completed' | 'disabled';
    nextStep: () => StepId;
    previousStep: () => StepId;
};

export const useCheckoutStepsState = create<CheckoutStepState>((set, get) => ({
    steps: ['info', 'delivery', 'payment'],
    activeStep: 'info',
    state: 'pending',
    setState: (state: CheckoutStepState['state']) => set({ state }),
    setActiveStep: (activeStep: StepId) => set({ activeStep }),
    nextStep: () => {
        const { steps, activeStep } = get();
        const next = steps.indexOf(activeStep) + 1;
        const nextStep = steps[next] ? next : steps.length - 1;
        return steps[nextStep];
    },
    previousStep: () => {
        const { steps, activeStep } = get();
        const previous = steps.indexOf(activeStep) - 1;
        const previousStep = steps[previous] ? previous : 0;
        return steps[previousStep];
    },
    getStepState: (step: StepId) => {
        const { steps, activeStep } = get();
        return steps.indexOf(step) > steps.indexOf(activeStep) ? 'active' : 'disabled';
    },
}));
