import React, { ForwardedRef } from 'react';
import { StyledLine } from './styled';

export type LineShade = 'color' | 'colorInverted';

export type LineProps = {
    alignment?: 'horizontal' | 'vertical';
    thickness?: number | string;
    shade?: LineShade;
    color?: string;
};

export const Line = React.forwardRef(
    ({ alignment = 'horizontal', ...restProps }: LineProps, ref?: ForwardedRef<HTMLDivElement>) => (
        <StyledLine ref={ref} alignment={alignment} {...restProps} />
    )
);
