import ChevronDown from '$icons/chevron-down.svg';
import ChevronUp from '$icons/chevron-up.svg';
import { Text } from '$shared/components';
import React, { ForwardedRef, ReactNode, useContext, useEffect, useMemo } from 'react';
import { AccordionContext } from '../../context/AccordionContext';
import {
    StyledAccordionContent,
    StyledAccordionContentInner,
    StyledAccordionHeader,
    StyledAccordionHeaderButton,
    StyledAccordionIconWrapper,
    StyledAccordionItem,
    StyledDefaultIcon,
} from '../../styled';
export type AccordionItemChildrenRenderProps = {
    isOpen: boolean;
    toggle: () => void;
    id: string;
};

export type AccordionItemProps = {
    /**
     * Text for the headline OR a component that will replace the default header and be used to trigger for the accordion
     */
    header?: string | ReactNode;

    /**
     * Controlls if the accordion is open or not
     */
    initAsOpen?: boolean;

    /**
     * Determines if accordion header font-size should be fluid or not
     */
    fluidFontSize?: boolean;

    /**
     * Override the unique generated id
     */
    id: string;

    /**
     * Overwrite the default icon
     */
    icon?: ReactNode | ((state: AccordionItemChildrenRenderProps) => React.ReactNode);

    /**
     * Accordion content
     */
    children?: React.ReactNode | ((state: AccordionItemChildrenRenderProps) => React.ReactNode);

    /**
     * Header content
     */
    variant?: 'default' | 'condensed' | 'submenu' | 'filter';
};

const defaultIcon = ({ isOpen }: { isOpen: boolean }) => (
    <StyledDefaultIcon as={isOpen ? ChevronUp : ChevronDown} />
);

/**
 * @description
 * Wraps content (children) in an accordion.
 * Should always be used inside a <Accordion>
 *
 * @example
 *  <AccordionItem
 *      id="1"
 *      icon={<Icon icon="instagram" size="lg" />}
 *      header="Woah this is a cool accordion">
 *      <p>Accordion body here!</p>
 *  </AccordionItem>
 *
 * @example
 *  <AccordionItem
 *      id="1"
 *      icon={<Icon icon="instagram" size="lg" />}
 *      header="Woah this is a cool accordion">
 * .    {({ isOpen }) => (
 *         `This accordion is ${isOpen ? 'open' : 'closed' }!`
 *      )}
 *  </AccordionItem>
 */

export const AccordionItem = React.forwardRef(
    (props: AccordionItemProps, ref: ForwardedRef<HTMLButtonElement>) => {
        const {
            id,
            header,
            icon = defaultIcon,
            initAsOpen = false,
            fluidFontSize = true,
            children,
            variant = 'default',
        } = props;
        const { states, idPrefix, toggle } = useContext(AccordionContext);

        const isOpen = useMemo(() => states.includes(id), [states, id]);

        const methods = useMemo(() => {
            return {
                toggle: () => toggle(id),
            };
        }, [id, toggle]);

        useEffect(() => {
            initAsOpen && methods.toggle();
        }, [initAsOpen, methods]);

        return (
            <StyledAccordionItem key={id} variant={variant}>
                <StyledAccordionHeader>
                    <StyledAccordionHeaderButton
                        ref={ref}
                        onClick={methods.toggle}
                        aria-expanded={isOpen}
                        aria-controls={`${idPrefix}control-${id}`}
                        id={`${idPrefix}${id}`}
                        type="button"
                        variant={variant}
                        expanded={isOpen}
                    >
                        {typeof header === 'string' ? (
                            <Text
                                variant="display4"
                                style={fluidFontSize ? undefined : { fontSize: '1em' }}
                                children={header}
                            />
                        ) : (
                            header
                        )}
                        {icon && (
                            <StyledAccordionIconWrapper>
                                {typeof icon === 'function'
                                    ? icon({ id, isOpen, ...methods })
                                    : icon}
                            </StyledAccordionIconWrapper>
                        )}
                    </StyledAccordionHeaderButton>
                </StyledAccordionHeader>
                <StyledAccordionContent
                    key={id}
                    initial="collapsed"
                    animate={isOpen ? 'open' : 'collapsed'}
                    exit="collapsed"
                    variants={{
                        open: {
                            display: '',
                            height: 'auto',
                            opacity: 1,
                        },
                        collapsed: {
                            height: 0,
                            opacity: 0,
                            transitionEnd: {
                                display: 'none',
                            },
                        },
                    }}
                    transition={{
                        type: 'spring',
                        stiffness: 300,
                        damping: 34,
                    }}
                    id={`accordion-control-${id}`}
                    role="region"
                    aria-labelledby={`accordion-${id}`}
                    hidden={!isOpen}
                >
                    <StyledAccordionContentInner variant={variant}>
                        {typeof children === 'function'
                            ? children({ id, isOpen, ...methods })
                            : children}
                    </StyledAccordionContentInner>
                </StyledAccordionContent>
            </StyledAccordionItem>
        );
    }
);
