import React, { cloneElement, KeyboardEvent, memo, useCallback, useRef, useState } from 'react';
import { StyledAccordion } from './styled';
import { AccordionContext } from './context/AccordionContext';
export type AccordionProps = {
    /**
     * Allows a single or multiple accordions to be open at onces.
     * `single` will automaticlly close other open accordions,
     * when opening a new accordion
     */
    type?: 'single' | 'multiple';

    /**
     * Use AccordionItem as children
     */
    children: React.ReactNode;
};

/**
 * @example
 * <Accordion>
 *     <AccordionItem id="1" header="How to request a refund." initAsOpen={true}>
 *         Reqeust a refund by...
 *     </AccordionItem>
 * </Accordion>
 */
export const Accordion = memo(({ type = 'multiple', children = [] }: AccordionProps) => {
    const [states, setStates] = useState<string[]>([]);
    const accordionRef = useRef<HTMLDivElement>(null);
    const idPrefix = 'accordion-';
    const childrenRef = useRef<HTMLButtonElement[]>([]);

    const toggle = useCallback(
        (stateId: string) => {
            setStates((previousStates) => {
                const idExists = previousStates.includes(stateId);

                if (idExists) {
                    return previousStates.filter((id) => id !== stateId);
                }

                if (type === 'single') {
                    return [stateId];
                }

                return [...previousStates, stateId];
            });
        },
        [setStates, type]
    );

    const onKeyDownHandler = useCallback(
        (event: KeyboardEvent<HTMLDivElement>) => {
            const activeIndex = childrenRef.current.findIndex((x) => x === event.target);

            switch (event.key) {
                case 'ArrowUp':
                    childrenRef.current[activeIndex - 1]?.focus();
                    event.preventDefault();
                    break;
                case 'ArrowDown':
                    childrenRef.current[activeIndex + 1]?.focus();
                    event.preventDefault();
                    break;
                case 'Home':
                    childrenRef.current[0]?.focus();
                    event.preventDefault();
                    break;
                case 'End':
                    childrenRef.current[childrenRef.current.length - 1]?.focus();
                    event.preventDefault();
                    break;
                default:
                    break;
            }
        },
        [childrenRef]
    );

    return (
        <StyledAccordion ref={accordionRef} onKeyDown={onKeyDownHandler}>
            <AccordionContext.Provider value={{ states, toggle, idPrefix }}>
                {Array.isArray(children)
                    ? children?.map((child, index) => {
                          if (React.isValidElement(child)) {
                              return cloneElement(child, {
                                  ref: (ref: HTMLButtonElement) => {
                                      childrenRef.current[index] = ref;
                                  },
                              } as { ref: (ref: HTMLButtonElement) => void });
                          }
                      })
                    : children}
            </AccordionContext.Provider>
        </StyledAccordion>
    );
});
