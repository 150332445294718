import { CSSObject } from '@emotion/react';

/**
 * Centers elements using flexbox
 */
export const center: CSSObject = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};
